import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import InfoSection from "../../components/infoSection"
import CtaGetCoffee from "../../components/ctaGetCoffee"
import { Link } from "gatsby"

const features = [
  {
    name: "1 French Press",
    description:
      "Säubere deine French Press, fülle und starte deinen Wasserkocher.",
    imageSrc: "/brewguide/frenchpress/frenchpress-1.JPEG",
    imageAlt: ""
  },
  {
    name: "2 Kaffeemehl wiegen",
    description:
      "Für unsere Rezeptempfehlung verwenden wir 60 Gramm Bohnen für 1 Liter Wasser. Je nach Volumen deiner French Press musst du das Rezept runterrechnen z.B. 30 Gramm auf 500ml, 15 Gramm auf 250ml",
    imageSrc: "/brewguide/frenchpress/frenchpress-2.JPEG",
    imageAlt: ""
  },
  {
    name: "3 Bohnen mahlen",
    description:
      "Das beste Ergebnis erzielst du immer mit frisch gemahlenen Bohnen. Mahle die gewünschte Menge immer erst kurz bevor du sie verwendest. Gieße die Bohnen in deine French Press.",
    imageSrc: "/brewguide/frenchpress/frenchpress-3.JPEG",
    imageAlt: ""
  },
  {
    name: "4 Aufgießen",
    description:
      "Gieße das kochende Wasser bis oben in deine French Ppess und warte 4 Minuten.",
    imageSrc: "/brewguide/frenchpress/frenchpress-4.JPEG",
    imageAlt: ""
  },
  {
    name: "5 Kruste aufbrechen",
    description:
      "Nach 4 Minuten nimm einen Löffel und brich die Kruste auf und rühre nur an der Oberfläche um. Du möchtest den Kaffee nicht komplett aufwirbeln.",
    imageSrc: "/brewguide/frenchpress/frenchpress-5.JPEG",
    imageAlt: ""
  },
  {
    name: "6 Warten",
    description:
      "Warte weitere 5 Minuten. Nach diesen 5 Minuten befindet sich nur noch Schaum an der Oberfläche.",
    imageSrc: "/brewguide/frenchpress/frenchpress-6.JPEG",
    imageAlt: ""
  },
  {
    name: "7 Kruste abschöpfen",
    description:
      "Nimm einen Löffel und schöpfe diesen Schaum ab in ein extra Gefäß. Dieser Schaum würde später bitter schmecken.",
    imageSrc: "/brewguide/frenchpress/frenchpress-7.JPEG",
    imageAlt: ""
  },
  {
    name: "8 Bereit zum trinken",
    description:
      "Setze den Kolben der French Press auf diese und drücke ihn nur unter die Kaffeeoberfläche. Er dient als Filter und soll nicht bis ganz nach unten gedrückt werden.",
    imageSrc: "/brewguide/frenchpress/frenchpress-8.JPEG",
    imageAlt: ""
  },
  {
    name: "9 Kaffee genießen",
    description:
      "Gieße nun vorsichtig deinen Kaffee ein und genieße ihn.",
    imageSrc: "/brewguide/frenchpress/frenchpress-9.JPEG",
    imageAlt: ""
  }
]

const tippsntricks = [
  {
    title: "Sauberkeit",
    description: "So einfach es klingt, aber die Sauberkeit deiner Maschine ist ausschlaggebend für einen guten Geschmack. Deswegen solltest du deine Maschine regelmäßig im besten Falle sogar täglich reinigen. Somit verlängerst du die Lebenszeit deiner Maschine und hast auch einen konstant guten Geschmack."
  },
  {
    title: "Genauigkeit",
    description: "Um konstant guten Kaffee zu erhalten muss genau gearbeitet werden. Wir empfehlen es die gemahlene Kaffeemenge immer abzuwiegen, um sicher zu gehen, dass sie gleich ist. Hierbei wird der Siebträger auf die Waage gestellt und tariert. Danach wird das Kaffeemehl gemahlen und der Siebträger mit dem Kaffeemehl erneut gewogen."
  },
  {
    title: "Veränderung des Rezepts",
    description: "Um wirklich einen Unterschied im Espresso zu erkennen, sollte immer nur EIN Parameter (Menge, Mahlgrad, Bezugszeit, Temperatur, Druckprofil) bei der Zubereitung verändert werden. Nur so kann ein wirklicher Unterschied zum vorherigen Shot hergestellt und bewertet werden, ob die Veränderung zu einer Verbesserung oder Verschlechterung geführt hat."
  },
  {
    title: "Mahlgrad",
    description: "Der Mahlgrad für Espresso ist allgemein sehr fein. Hellere Röstungen können feiner gemahlen werden, da die Bohnen nicht unkontrolliert splittern. Dunklere Röstungen sollten größer, aber immer noch fein, gemahlen werden"
  }
]

const SiebtraegerPage = () => (
  <Layout>
    <Seo title="Brew Guide" />

    <div className="bg-white">
      <div aria-hidden="true" className="relative">

        <StaticImage src="../../images/janko-ferlic-pR9D-7ztGmA-unsplash.jpg" alt="brew guide siebträger von caffeinity"
                     className="w-full h-96 object-center object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />
      </div>

    </div>


    <div>
      <div className="max-w-7xl mx-auto py-24 sm:py-32 sm:px-2 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="max-w-6xl text-center">
            <p
              className="mt-2 text-6xl font-extrabold tracking-tight sm:text-4xl text-caffeinity-green-dark">French Press</p>
            <p className="mt-4 text-xl text-gray-600 text-justify">
              Deine einfache Schritt für Schritt Anleitungen zu einem außergewöhnlichen Kaffee-Erlebnis für deine French Press.
            </p>
          </div>

          <div className="space-y-16 pt-10 mt-10 border-t border-gray-200 sm:pt-16 sm:mt-16">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
              >
                <div className="flex-auto lg:col-span-7 xl:col-span-8">
                  <div className="aspect-w-5 aspect-h-5 rounded-lg bg-gray-100 overflow-hidden">
                    <img src={feature.imageSrc} alt={feature.imageAlt} className="object-center object-cover" />
                  </div>
                </div>
                <div className="mt-6 lg:mt-0 lg:col-span-5 xl:col-span-4">
                  <h3 className="text-4xl font-bold text-caffeinity-green-dark">{feature.name}</h3>
                  <p className="mt-2 text-xl text-gray-600 mb-4 text-justify">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <Link
            to="/brewguide"
            className="btn-primary mt-24">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
            </svg>
            {" "}
            Zurück zu allen Brew Guides
          </Link>
        </div>
      </div>
    </div>

    <h1 className="bg-caffeinity-brown text-center py-14">Tipps & Tricks</h1>
    {tippsntricks.map((tipps) => (
      <InfoSection className="bg-caffeinity-pink text-caffeinity-green-dark" textHeading={tipps.title}
                   textSubtitle={tipps.description} />
    ))}

    <CtaGetCoffee />


  </Layout>
)

export default SiebtraegerPage
